import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { ServiceForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class ServiceUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Үйлчилгээ',
            key: shortid.generate(),
            name: '',
            description: '',
            image: '',
            enName: '',
            enDescription: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/services/${id}`, {
                progress: true,
                params: {
                    with: 'translations'
                }
            })

            const {
                name,
                description
            } = data.translations.find(val => val.locale === 'en')

            this.setState({
                key: shortid.generate(),
                name: data.name,
                description: data.description,
                image: data.image,
                enName: name,
                enDescription: description
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            description,
            image,
            enName,
            enDescription
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <ServiceForm
                    key={key}
                    path={`services/${id}`}
                    method="put"
                    name={name}
                    description={description}
                    image={image}
                    enName={enName}
                    enDescription={enDescription}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(ServiceUpdate)
