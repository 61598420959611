import React from 'react'
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom'
import {
    CoPartnerNew,
    CoPartners,
    CoPartnerUpdate,
    EmployeeNew,
    Employees,
    EmployeeUpdate,
    ForgotPassword,
    Home,
    Login,
    NotFound,
    PasswordReset,
    PostNew,
    Posts,
    PostUpdate,
    Register,
    Requests,
    ReviewNew,
    Reviews,
    ReviewUpdate,
    ServiceNew,
    Services,
    ServiceUpdate,
    UserNew,
    Users,
    UserUpdate,
    WorkNew,
    Works,
    WorkUpdate
} from './pages'
import { 
    SettingForm, 
    RequestForm 
} from './forms';

export default () => (
    <Router>
        <Switch>
            <Route
                path="/signin"
                component={Login}
            />
            <Route
                path="/signup"
                component={Register}
            />
            <Route
                path="/forgot/password"
                component={ForgotPassword}
            />
            <Route
                path="/password/reset/:token"
                component={PasswordReset}
            />
            <Route
                path="/"
                component={Home}
                exact
            />
            <Route
                path="/users/new"
                component={UserNew}
            />
            <Route
                path="/users/:id"
                component={UserUpdate}
            />
            <Route
                path="/users"
                component={Users}
            />
            <Route
                path="/works/new"
                component={WorkNew}
            />
            <Route
                path="/works/:id"
                component={WorkUpdate}
            />
            <Route
                path="/works"
                component={Works}
            />
            <Route
                path="/posts/new"
                component={PostNew}
            />
            <Route
                path="/posts/:id"
                component={PostUpdate}
            />
            <Route
                path="/posts"
                component={Posts}
            />
            <Route
                path="/employees/new"
                component={EmployeeNew}
            />
            <Route
                path="/employees/:id"
                component={EmployeeUpdate}
            />
            <Route
                path="/employees"
                component={Employees}
            />
            <Route
                path="/reviews/new"
                component={ReviewNew}
            />
            <Route
                path="/reviews/:id"
                component={ReviewUpdate}
            />
            <Route
                path="/reviews"
                component={Reviews}
            />
            <Route
                path="/partners/new"
                component={CoPartnerNew}
            />
            <Route
                path="/partners/:id"
                component={CoPartnerUpdate}
            />
            <Route
                path="/partners"
                component={CoPartners}
            />
            <Route
                path="/services/new"
                component={ServiceNew}
            />
            <Route
                path="/services/:id"
                component={ServiceUpdate}
            />
            <Route
                path="/services"
                component={Services}
            />
            <Route
                path="/settings"
                component={SettingForm}
            />
            <Route
                path="/requests/:id"
                component={RequestForm}
            />
            <Route
                path="/requests"
                component={Requests}
            />
            <Route component={NotFound} />
        </Switch>
    </Router>
)
