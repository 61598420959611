import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { WorkForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class WorkUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Төсөл',
            key: shortid.generate(),
            name: '',
            cover: '',
            images: [],
            location: '',
            square: '',
            date: '',
            team: '',
            enName: '',
            enLocation: '',
            enTeam: '',
            isFeatured: '',
            serviceId: '',
            services: []
        }
    }

    componentDidMount = () => {
        this.fetchData()
        this.fetchServices()
    }

    fetchServices = async () => {
        try {
            const { data } = await axios.get('/services', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                services: data
            })
        } catch (e) {
            //
        }
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const {
                data
            } = await axios.get(`/works/${id}`, {
                progress: true,
                params: {
                    with: 'translations'
                }
            })

            const {
                name,
                location,
                team
            } = data.translations.find(val => val.locale === 'en')

            this.setState({
                key: shortid.generate(),
                name: data.name,
                cover: data.cover,
                images: data.images,
                location: data.location,
                square: data.square,
                date: data.date,
                team: data.team,
                isFeatured: data.isFeatured,
                serviceId: data.serviceId,
                enName: name,
                enLocation: location,
                enTeam: team
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            cover,
            images,
            location,
            square,
            date,
            team,
            isFeatured,
            serviceId,
            services,
            enName,
            enLocation,
            enTeam
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <WorkForm
                    key={key}
                    path={`works/${id}`}
                    method="put"
                    name={name}
                    cover={cover}
                    images={images}
                    location={location}
                    square={square}
                    date={date}
                    team={team}
                    isFeatured={isFeatured}
                    serviceId={serviceId}
                    services={services}
                    enName={enName}
                    enLocation={enLocation}
                    enTeam={enTeam}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(WorkUpdate)
