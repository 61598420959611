import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Dashboard } from './../layouts'
import {
    TextField,
    Grid,
    Typography,
    Button
} from '@material-ui/core'
import { CustomSnackBar } from './../components'
import shortid from 'shortid'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class SettingForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Тохиргоо',
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            project: '',
            about: '',
            philosophy: '',
            email: '',
            number: '',
            address: '',
            facebook: '',
            instagram: '',
            pinterest: '',
            footer: '',
            enAbout: '',
            enProject: '',
            enPhilosophy: '',
            enAddress: '',
            enFooter: '',
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }


    fetchData = async () => {
        try {

            this.setState({
                key: shortid.generate()
            })

            const {
                data,
                translations
            } = await axios.get(`/option`, { progress: true })

            data.map(item =>
                this.setState({ [item.name]: item.value })
            )

            translations.map(item =>
                this.setState({ [item.name]: item.value })
            )

        } catch (e) {
            //
        }
    }

    update = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            project,
            about,
            philosophy,
            email,
            number,
            address,
            facebook,
            instagram,
            pinterest,
            footer,
            enAbout,
            enProject,
            enPhilosophy,
            enAddress,
            enFooter
        } = this.state

        try {
            await axios.put('/option', {
                project,
                about,
                philosophy,
                email,
                number,
                address,
                facebook,
                instagram,
                pinterest,
                footer,
                enAbout,
                enProject,
                enPhilosophy,
                enAddress,
                enFooter
            })

            this.setState({
                isSnackOpen: true,
                disabled: false
            })
        } catch (err) {
            //
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    render() {
        const {
            title,
            key,
            isSnackOpen,
            project,
            about,
            philosophy,
            email,
            number,
            address,
            facebook,
            instagram,
            pinterest,
            footer,
            enAbout,
            enProject,
            enPhilosophy,
            enAddress,
            enFooter
        } = this.state
        return (
            <Dashboard title={title}>
                <form
                    key={key}
                    onSubmit={this.update}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">
                                Нүүр хуудас
                            </Typography>
                            <TextField
                                label="Бидний тухай"
                                value={about}
                                onChange={this.handleChange('about')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Ажлууд
                            </Typography>
                            <TextField
                                label="Төслийн хүлээлт"
                                value={project}
                                onChange={this.handleChange('project')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Бидний тухай
                            </Typography>
                            <TextField
                                label="Компанийн философи"
                                value={philosophy}
                                onChange={this.handleChange('philosophy')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Холбоо барих
                            </Typography>
                            <TextField
                                label="И-мэйл"
                                value={email}
                                onChange={this.handleChange('email')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Утасны дугаар"
                                value={number}
                                onChange={this.handleChange('number')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Хаяг"
                                value={address}
                                onChange={this.handleChange('address')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Цахим хаягууд
                            </Typography>
                            <TextField
                                label="Facebook"
                                value={facebook}
                                onChange={this.handleChange('facebook')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Instagram"
                                value={instagram}
                                onChange={this.handleChange('instagram')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Pinterest"
                                value={pinterest}
                                onChange={this.handleChange('pinterest')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Доод хэсэг
                            </Typography>
                            <TextField
                                value={footer}
                                onChange={this.handleChange('footer')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                шинэчлэх
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">
                                Нүүр хуудас
                            </Typography>
                            <TextField
                                label="Бидний тухай"
                                value={enAbout}
                                onChange={this.handleChange('enAbout')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Ажлууд
                            </Typography>
                            <TextField
                                label="Төслийн хүлээлт"
                                value={enProject}
                                onChange={this.handleChange('enProject')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Бидний тухай
                            </Typography>
                            <TextField
                                label="Компанийн философи"
                                value={enPhilosophy}
                                onChange={this.handleChange('enPhilosophy')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Холбоо барих
                            </Typography>
                            <TextField
                                label="Хаяг"
                                value={enAddress}
                                onChange={this.handleChange('enAddress')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Доод хэсэг
                            </Typography>
                            <TextField
                                value={enFooter}
                                onChange={this.handleChange('enFooter')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <CustomSnackBar
                        message="Амжилттай шинэчлэгдлээ"
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </Dashboard>
        )
    }
}

export default withStyles(styles)(SettingForm)
