import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Grid
} from '@material-ui/core'
import DropZone from './../components/DropZone'
import {
    CustomSnackBar
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginBottom: {
        margin: '0 0 20px 0'
    }
})
class ReviewForm extends Component {

    static defaultProps = {
        name: '',
        position: '',
        image: '',
        body: '',
        enName: '',
        enPosition: '',
        enBody: '',
        isEmployee: false
    }

    constructor(props) {
        super(props)

        const {
            name,
            position,
            image,
            body,
            enName,
            enPosition,
            enBody,
            isEmployee
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            name,
            position,
            image,
            body,
            locale: 'en',
            enName,
            enPosition,
            enBody,
            isEmployee
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            name: '',
            position: '',
            image: '',
            body: '',
            enName: '',
            enPosition: '',
            enBody: '',
            isEmployee: false
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            name,
            position,
            image,
            body,
            locale,
            enName,
            enPosition,
            enBody,
            isEmployee
        } = this.state

        try {
            await axios[method](path, {
                name,
                position,
                image,
                body,
                locale,
                enName,
                enPosition,
                enBody,
                isEmployee
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({ errors })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onChange = (path = '') => {
        this.setState({
            image: path
        })
    }

    onRadioChange = e => {
        const { checked } = e.target

        this.setState({
            isEmployee: checked
        })
    }

    render() {
        const {
            name,
            position,
            image,
            body,
            enName,
            enPosition,
            enBody,
            isEmployee,
            isSnackOpen,
            errors
        } = this.state

        const { marginBottom } = this.props.classes

        const { message } = this.props
        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                error={!!errors.name}
                                helperText={errors.name}
                                label="Нэр"
                                value={name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.position}
                                helperText={errors.position}
                                label="Албан тушаал"
                                value={position}
                                onChange={this.handleChange('position')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <div className={marginBottom}>
                                <DropZone
                                    uploadPath="image/upload"
                                    onChange={this.onChange}
                                    file={image}
                                />
                            </div>
                            <TextField
                                error={!!errors.body}
                                helperText={errors.body}
                                label="Сэтгэгдэл"
                                value={body}
                                onChange={this.handleChange('body')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <div className={marginBottom}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isEmployee}
                                            onChange={this.onRadioChange}
                                            value="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Ажилтан"
                                />
                            </div>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                Хадгалах
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={!!errors.enName}
                                helperText={errors.enName}
                                label="Нэр"
                                value={enName}
                                onChange={this.handleChange('enName')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.enPosition}
                                helperText={errors.enPosition}
                                label="Албан тушаал"
                                value={enPosition}
                                onChange={this.handleChange('enPosition')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.enBody}
                                helperText={errors.enBody}
                                label="Сэтгэгдэл"
                                value={enBody}
                                onChange={this.handleChange('enBody')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                        <CustomSnackBar
                            message={message}
                            open={isSnackOpen}
                            snackClose={this.snackClose}
                        />
                    </Grid>
                </form>
            </div >
        )
    }
}

export default withStyles(styles)(ReviewForm)
