import React, { Component } from 'react'
import { Dashboard } from './../layouts'
import {
    ActionUpdateButton,
    BaseTable
} from './../components'
import { pathJoin } from './../utils/helpers'

const columns = [
    {
        name: 'firstName',
        title: 'Нэр'
    },
    {
        name: 'email',
        title: 'И-мэйл'
    },
    {
        name: 'message',
        title: 'Мессеж'
    },
    {
        name: 'created',
        title: 'Үүсгэсэн'
    },
    {
        name: 'updated',
        title: 'Шинэчилсэн'
    },
    {
        getCellValue: ({ id }) => <ActionUpdateButton path={pathJoin('requests', id)} />
    }
]

const dateColumns = [
    'created', 'updated'
]

export default class Works extends Component {
    constructor(props) {
        super(props)
        this.table = React.createRef()
    }

    fetchData = () => {
        return this.table.current.fetchData()
    }

    render() {
        return (
            <Dashboard title="Хүсэлт">
                <BaseTable
                    path="requests"
                    columns={columns}
                    dateColumns={dateColumns}
                    innerRef={this.table}
                />
            </Dashboard>
        )
    }
}
