import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { PostForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class PostUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Блог',
            key: shortid.generate(),
            name: '',
            images: [],
            body: '',
            enTitle: '',
            enBody: ''
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/posts/${id}`, {
                progress: true,
                params: {
                    with: [
                        'translations'
                    ]
                }
            })

            const translation = data.translations.find(item => item.locale === 'en')

            this.setState({
                key: shortid.generate(),
                name: data.title,
                images: data.images,
                body: data.body,
                enTitle: translation.title,
                enBody: translation.body
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            images,
            body,
            enTitle,
            enBody
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <PostForm
                    key={key}
                    path={`posts/${id}`}
                    method="put"
                    title={name}
                    images={images}
                    body={body}
                    enTitle={enTitle}
                    enBody={enBody}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(PostUpdate)
