import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { ReviewForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class ReviewUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Мэдээ',
            key: shortid.generate(),
            name: '',
            position: '',
            image: '',
            body: '',
            enName: '',
            enPosition: '',
            enBody: '',
            isEmployee: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/reviews/${id}`, {
                progress: true,
                params: {
                    with: 'translations'
                }
            })

            const {
                name,
                position,
                body
            } = data.translations.find(val => val.locale === 'en')

            this.setState({
                key: shortid.generate(),
                name: data.name,
                position: data.position,
                image: data.image,
                body: data.body,
                enName: name,
                enPosition: position,
                enBody: body,
                isEmployee: data.isEmployee
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            position,
            image,
            body,
            enName,
            enPosition,
            enBody,
            isEmployee
        } = this.state
        const { match } = this.props
        const { id } = match.params

        return (
            <Dashboard title={title}>
                <ReviewForm
                    key={key}
                    path={`reviews/${id}`}
                    method="put"
                    name={name}
                    position={position}
                    image={image}
                    body={body}
                    enName={enName}
                    enPosition={enPosition}
                    enBody={enBody}
                    isEmployee={isEmployee.toString()}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(ReviewUpdate)
