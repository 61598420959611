import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Grid,
    Box,
    FormControl,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    OutlinedInput,
    Checkbox
} from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
    DropZoneMultiple,
    DropZone
} from './../components'
import { CustomSnackBar } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginLeft: {
        margin: '0 10px 0 0'
    },
    marginBottom: {
        margin: '0 0 20px 0'
    }
})

class WorkForm extends Component {

    static defaultProps = {
        name: '',
        cover: '',
        images: [],
        location: '',
        square: '',
        date: null,
        team: '',
        enName: '',
        enLocation: '',
        enTeam: '',
        isFeatured: false,
        serviceId: '',
        services: []
    }

    constructor(props) {
        super(props)

        const {
            name,
            location,
            cover,
            images,
            square,
            date,
            team,
            enName,
            enLocation,
            enTeam,
            serviceId,
            services,
            isFeatured
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            name,
            cover,
            location,
            images,
            square,
            date,
            team,
            locale: 'en',
            enName,
            enLocation,
            enTeam,
            isFeatured,
            serviceId,
            services
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            name: '',
            images: [],
            cover: '',
            location: '',
            square: '',
            date: null,
            team: '',
            enName: '',
            enLocation: '',
            enTeam: '',
            isFeatured: false,
            serviceId: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            name,
            location,
            cover,
            images,
            square,
            date,
            team,
            locale,
            enName,
            enLocation,
            enTeam,
            isFeatured,
            serviceId
        } = this.state

        try {
            await axios[method](path, {
                name,
                location,
                cover,
                images,
                square,
                date,
                team,
                locale,
                enName,
                enLocation,
                enTeam,
                isFeatured,
                serviceId
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({ errors })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onCoverChange = (path = '') => {
        this.setState({
            cover: path
        })
    }

    onChange = (path, bool) => {
        let copyImages = []
        if (bool === undefined) {
            this.setState(prevState => ({
                images: [...prevState.images, path]
            }))
        } else {
            copyImages = this.state.images.filter(val =>
                val !== path
            )
            this.setState({
                images: copyImages
            })
        }
    }

    dateChanged = date => {
        this.setState({ date })
    }

    selectChanged = e => {
        const { value } = e.target

        this.setState({ serviceId: value })
    }

    onFeaturedChanged = e => {
        const { checked } = e.target
        this.setState({
            isFeatured: checked
        })
    }

    render() {
        const {
            name,
            location,
            cover,
            images,
            square,
            date,
            team,
            enName,
            enLocation,
            enTeam,
            serviceId,
            services,
            isFeatured,
            isSnackOpen,
            errors
        } = this.state

        const {
            message
        } = this.props

        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                error={!!errors.name}
                                helperText={errors.name}
                                label="Нэр"
                                value={name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.location}
                                helperText={errors.location}
                                label="Байршил"
                                value={location}
                                onChange={this.handleChange('location')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.square}
                                helperText={errors.square}
                                label="Талбайн хэмжээ (m²)"
                                value={square}
                                onChange={this.handleChange('square')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.team}
                                helperText={errors.team}
                                label="Баг"
                                value={team}
                                onChange={this.handleChange('team')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Box my={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        fullWidth
                                        views={["year"]}
                                        label="Дууссан он"
                                        value={date}
                                        onChange={this.dateChanged}
                                        inputVariant="outlined"
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            <Box my={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="serviceId-simple">Үйлчилгээ</InputLabel>
                                    <Select
                                        value={serviceId}
                                        onChange={this.selectChanged}
                                        input={
                                            <OutlinedInput name="serviceId" id="serviceId-simple" />
                                        }
                                    >
                                        {
                                            services.map(val =>
                                                <MenuItem
                                                    key={val.id}
                                                    value={val.id}
                                                >
                                                    {val.name}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box my={3}>
                                <Typography variant="subtitle1">Арын зураг/бичлэг</Typography>
                                <DropZone
                                    uploadPath="file/upload"
                                    onChange={this.onCoverChange}
                                    file={cover}
                                />
                            </Box>
                            <Box my={3}>
                                <Typography variant="subtitle1">Дэлгэрэнгүй зураг</Typography>
                                <DropZoneMultiple
                                    uploadPath="image/upload"
                                    onChange={this.onChange}
                                    files={images}
                                />
                            </Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isFeatured}
                                        onChange={this.onFeaturedChanged}
                                        value="isFeatured"
                                        color="primary"
                                    />
                                }
                                label="Нүүр хуудас дээр харуулах"
                            />
                            <Box my={3}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={!!errors.enName}
                                helperText={errors.enName}
                                label="Нэр"
                                value={enName}
                                onChange={this.handleChange('enName')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.enLocation}
                                helperText={errors.enLocation}
                                label="Байршил"
                                value={enLocation}
                                onChange={this.handleChange('enLocation')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.enTeam}
                                helperText={errors.enTeam}
                                label="Баг"
                                value={enTeam}
                                onChange={this.handleChange('enTeam')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(WorkForm)
